<template>
  <div id="mian">
    <!-- 批量订单查询 -->
    <div>
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>店铺名称</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="店铺名称/编号"
            ></el-input>
          </div>
          <div class="item">
            <i>所属商户</i>
            <el-select
              v-model="query.mchId"
              placeholder="请选择商户名称"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in MerchantsDropList"
                :key="item.mchId"
                :label="item.mchName"
                :value="item.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <el-select v-model="value" placeholder="全部" style="width:200px;height:36px;">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
          <div class="item">
            <i>产品类型</i>
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in payproductsDropList"
                :key="item.productId"
                :label="item.productName"
                :value="item.productId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>统计日期</i>
            <el-date-picker
              v-model="query.settleStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.settleEndDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="onSearch">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>统计日期</p>
            </td>
            <td>
              <p>店铺名称</p>
              <p>店铺编号</p>
            </td>
            <td>
              <p>报备商户</p>
              <p>报备商户号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>成功订单(笔)</p>
            </td>
            <td>
              <p>订单总数(笔)</p>
            </td>
            <td>
              <p>成功率(%)</p>
            </td>
            <td>
              <p>交易净额(元)</p>
            </td>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>
              <p>{{ item.settleDate }}</p>
            </td>
            <td>
              <p>{{ item.storeName }}</p>
              <p>{{ item.storeId }}</p>
            </td>
            <td>
              <p>{{ item.tradeMchName }}</p>
              <p>{{ item.tradeMchNo }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
            </td>
            <td>
              <p>{{ item.productName }}</p>
            </td>
            <td>
              <p>{{ item.tradeNum }}</p>
            </td>
            <td>
              <p>{{ item.orderNum }}</p>
            </td>
            <td>
              <p>{{ item.successRate }}</p>
            </td>
            <td>
              <p>{{ (item.tradeNetAmount / 100) | formatMoney }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { storeStatistics } from "@/api/data/data.js";
import { merchantsDrop, payproductsDrop } from "@/api/common.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      value1: "",
      value2: "",
      crumbs: "批量查询",
      MerchantsDropList: [], //所属商户
      payproductsDropList: [], // 产品类型
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        settleStartDate: formatDate(
          new Date() - 24 * 60 * 60 * 1000,
          "yyyy-MM-dd"
        ),
        settleEndDate: formatDate(
          new Date() - 24 * 60 * 60 * 1000,
          "yyyy-MM-dd"
        ),
        productId: "",
        keyword: "",
      },
      tableData: [],
      total: 0,
    };
  },
  activated() {
    this.getLists();
    this.getMerchantsDrop();
    this.getPayproductsDrop();
  },
  computed: {
    ...mapState({
      dptjDetail: "data_dptjQuery",
    }),
  },
  created() {

    if (this.dptjDetail) {
      this.query = this.dptjDetail.query;
    console.log("店铺统计页面进来时,拿到的缓存的数据为",this.query)

    }
    this.getLists(false);
    this.getMerchantsDrop();
    this.getPayproductsDrop();
    this.$enter(this.$route.path, this.onSearch);
  },
  methods: {
    ...mapMutations({
      setDptjDeal: "data_setDptj",
    }),
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
    //获取列表数据
    getLists(reset = true) {
      if (reset) {
        (this.query.pageNo = 1),
          (this.query.keyword = this.query.keyword.trim());
      }
      if (!this.query.settleStartDate || !this.query.settleEndDate) {
        this.$message.error("统计日期时间段必选");
        return;
      }
      storeStatistics(this.query)
        .then((res) => {
          if (res.resultData) {
            this.tableData = res.resultData.settles;
            if (this.tableData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.total = res.resultData.totalNum;
          }
        })
        .catch();
    },
    // 商户下拉列表
    getMerchantsDrop() {
      merchantsDrop()
        .then((res) => {
          this.MerchantsDropList = res.resultData;
        })
        .catch();
    },
    getPayproductsDrop() {
      payproductsDrop()
        .then((res) => {
          this.payproductsDropList = res.resultData;
        })
        .catch();
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //  查询
    onSearch() {
      this.getLists();
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    console.log("店铺统计页面离开时缓存的数据为",data)
    this.setDptjDeal(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime {
    width: 49.58%;
}
</style>
